@import "globals.scss";

.main {
  @media (min-width: $media-size-1) {
    width: $content-width-0;
  }
  @media (max-width: $media-size-1) {
    width: $content-width-1;
  }
  @media (max-width: $media-size-2) {
    width: $content-width-2;
  }
  @media (max-width: $media-size-3) {
    width: $content-width-3;
  }
  @media (max-width: $media-size-4) {
    width: $content-width-4;
  }

  margin: 3rem auto 2rem auto; 

  flex: 1;
}

.postSummaryTile {
  margin-bottom: 3.5rem;
  &:last-child {
    margin-bottom: 0;
  }
}