@import "globals.scss";

.postSummary {
  img {
    @media (min-width: $media-size-1) {
      max-width: $post-image-width-0;
    }
    @media (max-width: $media-size-1) {
      max-width: $post-image-width-1;
    }
    @media (max-width: $media-size-2) {
      max-width: $post-image-width-2;
    }
    @media (max-width: $media-size-3) {
      max-width: $post-image-width-3;
    }
    @media (max-width: $media-size-4) {
      max-width: $post-image-width-4;
    }
    
    margin-bottom: 1rem;
  }

  footer a {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: $text-color;
    &:hover {
      color: $link-hover-color;
    }
    &::after {
      content: " →";
    }
  }
}
