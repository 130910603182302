/*
576px, 768px, 992px, and 1200px
*/

$media-size-1: 1200px;
$media-size-2: 992px;
$media-size-3: 768px;
$media-size-4: 576px;

$media-size-small: $media-size-3;

$content-width-0: 1170px;
$post-image-width-0: 800px;

$content-width-1: 980px;
$post-image-width-1: 800px;

$content-width-2: 755px;
$post-image-width-2: 100%;

$content-width-3: 570px;
$post-image-width-3: 100%;

$content-width-4: 95%;
$post-image-width-4: 100%;

$date-font-size: 0.8rem;
$date-font-color: rgb(112, 112, 112);

$logo-color: rgb(69, 69, 69);
$link-hover-color: rgb(123, 196, 230);

$title-color: rgb(48, 48, 48);
$text-color: rgb(89, 89, 89);
