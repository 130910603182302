@import "globals.scss";

.main {
  img {
    width: 100%;
    margin-bottom: 1rem;
  }

  @media (min-width: $media-size-1) {
    width: $content-width-0;
  }
  @media (max-width: $media-size-1) {
    width: $content-width-1;
  }
  @media (max-width: $media-size-2) {
    width: $content-width-2;
  }
  @media (max-width: $media-size-3) {
    width: $content-width-3;
  }
  @media (max-width: $media-size-4) {
    width: $content-width-4;
  }

  margin: 3rem auto 0 auto;

  pre {
    overflow: auto;
  }
  
  flex: 1;

  margin-bottom: 2rem;
}
