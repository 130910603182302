@import "globals.scss";

.navbar {
  .title {
    display: grid;
    @media (min-width: $media-size-small) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
    @media (max-width: $media-size-small) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
    }

    @media (min-width: $media-size-1) {
      width: $content-width-0;
    }
    @media (max-width: $media-size-1) {
      width: $content-width-1;
    }
    @media (max-width: $media-size-2) {
      width: $content-width-2;
    }
    @media (max-width: $media-size-3) {
      width: $content-width-3;
    }
    @media (max-width: $media-size-4) {
      width: $content-width-4;
    }
    
    height: 7rem;
    
    margin: 0 auto;
    font-size: 0.8rem;

    a {
      display: inline-block;
    }
    a .logo {
      width: 100%;
      text-align: center;
      color: $logo-color;
    }
    a:hover .logo {
      color: $link-hover-color;
    }

    .logoContainer{
      @media (min-width: $media-size-small) {
        grid-row-start: 2;
        grid-column-start: 2;
      }
      @media (max-width: $media-size-small) {
        grid-row-start: 1;
        grid-column-start: 1;
      }

      text-align: center;
    }
    
    .contact {
      display: flex;
      @media (min-width: $media-size-small) {
        grid-row-start: 2;
        grid-column-start: 3;

        align-items: center;
        justify-content: right;
      }
      @media (max-width: $media-size-small) {
        grid-row-start: 2;
        grid-column-start: 1;

        align-items: center;
        justify-content: center;
      }
    }
  }

  .stripe {
    width: 100%;
    height: 60px;
    background: rgb(243, 243, 243);
  }
}
