@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Lora:ital,wght@0,400;0,700;1,400;1,700&family=Work+Sans:ital,wght@0,400;0,500;0,700;0,900;1,400;1,700;1,900&display=swap');
@import "globals.scss";

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  min-height: 100%;
  display: flex;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

html {
  font-size: 18px;
  height: 100%;
}

* {
  transition: color 150ms ease-in 0s;
}

a {
  text-decoration: none;
}

a:hover {
  color: rgb(123, 196, 229);
  text-decoration: none;
}