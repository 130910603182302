@import "globals.scss";

.logo {
  font-style: italic;
  font-weight: 900;
  font-family: 'Lato', sans-serif;
  color: $logo-color;
  font-size: 3rem;
  text-transform: lowercase;
}
