@import "globals.scss";

.postTitle, .postTitle a {
  color: $title-color;
  line-height: 1.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: -0.03rem;
}

.postTitle a:hover {
  color: $link-hover-color;
}
